import Welcome from 'components/welcome';
import type { NextPage } from 'next';

const Home: NextPage = () => {
  return (
    <>
      <Welcome></Welcome>
    </>
  )
}

export default Home
